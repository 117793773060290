import axios, { AxiosRequestConfig } from "axios";
import { apiConfig, axiosConfigWithAuthorizationHeader } from "../common/api";
import { User } from "../models/User";
import { Person } from "../models/types";

export const getAllUsers = async (token: string): Promise<Person[]> => {
  const config: AxiosRequestConfig = axiosConfigWithAuthorizationHeader(token);
  return await axios.get<Person[]>(apiConfig.usersURL, config).then((response) => {
    return response.data;
  });
};

export const getLoggedUserDetails = async (token: string): Promise<User> => {
  const config: AxiosRequestConfig = axiosConfigWithAuthorizationHeader(token);
  return await axios.get<User>(`${apiConfig.usersURL}/me`, config).then((response) => {
    return response.data;
  });
};

export const getUserByID = (id: any) => {
  return axios.get<Person>(`/users/${id}`);
};

import { IconButton, useTheme } from "@mui/material";
import React, { useState } from "react";
import SotmDrawer from "./SotmDrawer";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";

const SotmAppBar = () => {
  const theme = useTheme();
  const [state, setState] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  return (
    <header>
      <div className="container">
        <div data-testid="app-bar" className="app-bar row no-margin">
          <IconButton onClick={() => navigate("/")} sx={{ padding: 0 }}>
            <img
              src="./assets/pics/sotm_logo_white.svg"
              alt="sotm_logo"
              style={{ height: "30px", fill: theme.palette.primary.light }}
              data-testid="logo"
            />
          </IconButton>
          <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <Menu data-testid='drawer-menu'/>
          </IconButton>
        </div>
        <SotmDrawer state={state} toggleDrawer={toggleDrawer} />
      </div>
    </header>
  );
};

export default SotmAppBar;

import { Box, Paper } from "@mui/material";
import { useContext } from "react";
import Competition from "./Competition";
import { CompetitionsContext } from "../../state/competitionsContext";

const CurrentCompetitions = () => {
  const { competitions } = useContext(CompetitionsContext);

  const CompetitionBox = () => (
    <Box data-testid="current-nominations" className="col-half-responsive">
      <Paper>
        {competitions.map((competition, idx, collection) => (
          <Competition
            competition={competition}
            key={competition.competitionId}
            isLastElement={idx === collection.length - 1}
          />
        ))}
      </Paper>
    </Box>
  );

  return <CompetitionBox />;
};

export default CurrentCompetitions;

import React, { useEffect, useState } from "react";
import { User, UserVote } from "../models/User";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authentication/authConfig";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { getLoggedUserDetails } from "../services/userService";

type UserContextType = {
  loggedUser: User | undefined;
  isLogged: boolean;
  login: () => void;
  logout: () => void;
  getAuthToken: () => Promise<string>;
  refetchLoggedUserDetails: () => Promise<void>;
  hasUserAlreadyVoted: (competitionId: string, votingSessionId: string) => boolean | undefined;
  hasUserAlreadyVotedForPerson: (
    competitionId: string,
    votingSessionId: string,
    votedUserId: string,
  ) => boolean | undefined;
};

export const UserContext = React.createContext<UserContextType>({
  loggedUser: undefined,
  isLogged: false,
  login: () => {},
  logout: () => {},
  getAuthToken: async () => {
    return Promise.resolve("");
  },
  refetchLoggedUserDetails: () => {
    return Promise.resolve();
  },
  hasUserAlreadyVoted: () => false,
  hasUserAlreadyVotedForPerson: () => false,
});

const UserContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [loggedUser, setLoggedUser] = useState<User | undefined>(undefined);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && loggedUser === undefined) {
      getUserDetailsFromApi();
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    instance.enableAccountStorageEvents();

    const callbackId = instance.addEventCallback((message: any) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        // Do something with the result
      } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
        setLoggedUser(undefined);
        navigate("/login");
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const hasUserAlreadyVotedHandler = (competitionId: string, votingSessionId: string): boolean | undefined => {
    const vote: UserVote | undefined = loggedUser?.votes?.find(
      (vote) => vote.competitionId === competitionId && vote.votingSessionId === votingSessionId,
    );
    return vote !== undefined;
  };
  const hasUserAlreadyVotedForPersonHandler = (
    competitionId: string,
    votingSessionId: string,
    votedUserId: string,
  ): boolean | undefined => {
    const vote: UserVote | undefined = loggedUser?.votes?.find(
      (vote) =>
        vote.competitionId === competitionId &&
        vote.votingSessionId === votingSessionId &&
        vote.votedPerson.userId === votedUserId,
    );
    return vote !== undefined;
  };

  const getUserDetailsFromApi = async () => {
    const token = await getAuthTokenHandler();
    const user = await getLoggedUserDetails(token);
    setLoggedUser(user);
  };

  const loginHandler = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
      setLoggedUser(undefined);
    });
  };

  const logoutHandler = () => {
    instance.logoutRedirect();
  };

  const getAuthTokenHandler = async () => {
    try {
      const authenticationResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return Promise.resolve(authenticationResult.accessToken);
    } catch (e) {
      logoutHandler();
      return Promise.reject("Unauthorized");
    }
  };

  const contextValue: UserContextType = {
    loggedUser: loggedUser,
    isLogged: isAuthenticated,
    login: loginHandler,
    logout: logoutHandler,
    getAuthToken: getAuthTokenHandler,
    refetchLoggedUserDetails: getUserDetailsFromApi,
    hasUserAlreadyVoted: hasUserAlreadyVotedHandler,
    hasUserAlreadyVotedForPerson: hasUserAlreadyVotedForPersonHandler,
  };

  return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;

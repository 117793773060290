import { Box, Stack, Typography } from "@mui/material";
import { theme } from "../../common/theme";
import { NomineeDTO, NomineeWithVotesDTO } from "../../models/types";
import React from "react";

export const NominationsSection = ({ nominations }: Pick<NomineeWithVotesDTO | NomineeDTO, "nominations">) => (
  <>
    {nominations.map((nomination, idx, collection) => (
      <Box key={nomination.fromPerson.userId + nomination.createDate} className="nomination-tile">
        <Typography variant="body1" gutterBottom>
          {nomination.justification}
        </Typography>
        <Stack direction="row">
          <Typography gutterBottom variant="subtitle2" className="nominated-by">
            <span style={{ color: theme.custom.accentColor }}>nominated by &nbsp;</span>
            {nomination.fromPerson.firstName}&nbsp;{nomination.fromPerson.lastName}
          </Typography>
        </Stack>
      </Box>
    ))}
  </>
);
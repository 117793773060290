import ProfilePicture from "../profilePicture/ProfilePicture";
import { Person } from "../../models/types";

const EmployeeTile = ({ employee, onClick }: { employee: Person; onClick: () => void }) => (
  <button key={employee.userId} type="button" className="es-option" onClick={onClick}>
    <ProfilePicture photoUrl={employee?.photoUrl} />
    <div className="initials">
      {employee.firstName}
      <br />
      {employee.lastName}
    </div>
  </button>
);

export default EmployeeTile;

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useState } from "react";
import { CompetitionWithNomineesHistoryDTO } from "../../models/types";
import { UserContext } from "../../state/userContext";
import { fetchNominationsHistoryByYear } from "../../services/nominationService";
import { HistoryCompetition } from "./HistoryCompetition";
import BoxWithLoader from "../BoxWithLoader/BoxWithLoader";

type NominationsHistoryAccordionProps = {
  year: string;
};

export const NominationsHistoryAccordion = ({ year }: NominationsHistoryAccordionProps) => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [competitions, setCompetitions] = useState<CompetitionWithNomineesHistoryDTO[]>([]);

  const handleChange = async (event: React.SyntheticEvent, expanded: boolean) => {
    if (isLoading && expanded) {
      try {
        setIsLoading(true);
        const token = await userContext.getAuthToken();
        const history = await fetchNominationsHistoryByYear(token, year);
        setCompetitions(history);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Accordion data-testid={`history-accordion-${year}`} onChange={handleChange} defaultExpanded={false} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h4">SOTM {year}</Typography>
      </AccordionSummary>
      {isLoading ? (
        <BoxWithLoader background={false} />
      ) : (
        <AccordionDetails data-testid={`history-accordion-${year}-details`} className="details">
          {competitions.map((competition, idx, collection) => (
            <HistoryCompetition
              key={competition.competitionId}
              isLast={idx === collection.length - 1}
              competition={competition}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

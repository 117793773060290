import { Dialog } from "@mui/material";
import * as React from "react";
import { useContext } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { UserContext } from "../../state/userContext";
import { voteFor } from "../../services/voting/votingService";
import { SnackbarContext } from "../../state/snackbarContext";
import { Person } from "../../models/types";

type VoteConfirmationProps = {
  isOpen: boolean;
  competitionId: string;
  nominee: Person;
  onClose: () => void;
};

const VoteConfirmation = ({ isOpen, competitionId, nominee, onClose }: VoteConfirmationProps) => {
  const userContext = useContext(UserContext);
  const { openSuccessSnack, openInfoSnack, openErrorSnack } = useContext(SnackbarContext);

  const handleVote = async () => {
    try {
      await openInfoSnack("Vote sent");
      const token = await userContext.getAuthToken();
      await voteFor({ competitionId, votingToPersonId: nominee.userId }, token);
      await openSuccessSnack("You voted!");
      await userContext.refetchLoggedUserDetails();
    } catch (err: any) {
      await openErrorSnack(err.response.data.error);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-nomination-confirmation-dialog"
      className="dialog-buttons"
    >
      <DialogTitle id="vote-confirmation-dialog-title">
        {`Do you want to vote for ${nominee.firstName} ${nominee.lastName}?`} <br />
        You will not be able to change your vote.
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleVote} autoFocus>
          Vote
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoteConfirmation;

import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { EmployeesContext } from "../../state/employeesContext";
import "./EmployeeSearch.style.scss";
import { Person } from "../../models/types";

import EmployeeTile from "./EmployeeTile";
import NominatePersonDialog from "../nominatePersonDialog/NominatePersonDialog";

const EmployeeSearch = () => {
  const employeesContext = useContext(EmployeesContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedNominee, setSelectedNominee] = useState(null as Person | null);
  const [displayData, setDisplayData] = useState<Person[]>([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [isListScrolled, setIsListScrolled] = useState(false);
  const [isListVisible, setIsListVisible] = useState(false);

  const handleEmployeeClick = useCallback((nominee: Person) => {
    setSelectedNominee(nominee);
    setIsDialogOpen(true);
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  }, []);

  const handleOptionsScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const scrollTop = event.currentTarget.scrollTop;
      if (scrollTop >= 20 && !isListScrolled) {
        setIsListScrolled(true);
      } else if (scrollTop < 20 && isListScrolled) {
        setIsListScrolled(false);
      }
    },
    [isListScrolled],
  );

  useEffect(() => {
    setDisplayData(employeesContext.employees);
  }, [employeesContext.employees]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const filteredEmployees = employeesContext.employees.filter((employee) =>
        `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(searchPhrase),
      );
      setDisplayData(filteredEmployees);
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchPhrase]);

  return (
    <div data-testid="employeesList" className="row col-half-responsive sticky-top">
      <Paper className="employee-search">
        <div className={`es-input-wrapper ${isListScrolled && "is-scrolled"}`}>
          <input
            type="text"
            placeholder="Search your nominee..."
            onChange={handleChange}
            onFocus={() => setIsListVisible(true)}
            onBlur={() => setIsListVisible(false)}
          />
          <SearchIcon className="es-search-icon" />
        </div>

        <div className={`es-options ${isListVisible && "show"}`} onScroll={handleOptionsScroll}>
          {displayData.map((employee) => (
            <EmployeeTile key={employee.userId} employee={employee} onClick={() => handleEmployeeClick(employee)} />
          ))}
        </div>
      </Paper>

      <NominatePersonDialog
        open={isDialogOpen}
        nominee={selectedNominee}
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedNominee(null);
        }}
      />
    </div>
  );
};
export default EmployeeSearch;

import { NominationsHistory } from "../../components/nomineesHistory/NominationsHistory";
import Page from "../../components/page/Page";

const NomineesHistoryPage = () => {
  return (
    <Page>
      <NominationsHistory />
    </Page>
  );
};

export default NomineesHistoryPage;

import { Configuration } from "@azure/msal-browser";

const AZURE_AD_CLIENT_ID = process.env.REACT_APP_AZURE_AD_CLIENT_ID as string;
const AZURE_AD_TENANT_ID = process.env.REACT_APP_AZURE_AD_TENANT_ID as string;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + AZURE_AD_TENANT_ID,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 10000,
  },
} as Configuration;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};

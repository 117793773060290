import axios from "axios";
import { apiConfig, axiosConfigWithAuthorizationHeader } from "../../common/api";
import { YearlyWinner } from "../../models/types";

export const fetchAllYearlyWinners = async (accessToken: string): Promise<YearlyWinner[]> => {
  const response = await axios.get<YearlyWinner[]>(
    apiConfig.yearlyWinnersURL,
    axiosConfigWithAuthorizationHeader(accessToken),
  );

  response.data.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  return response.data;
};

import Login from "./pages/login/Login";
import "./styles/App.scss";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import { theme } from "./common/theme";
import Protected from "./components/protected/Protected";
import Faq from "./pages/faq/Faq";
import { SotmContextProvider } from "./state/sotmContext";
import NomineesHistoryPage from "./pages/nomineesHistory/NomineesHistoryPage";
import SuccessOfTheYear from "./pages/successOfTheYear/SuccessOfTheYear";

function App() {
  return (
    <Router>
      <SotmContextProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <Protected>
                  <Home />
                </Protected>
              }
            />
            <Route
              path="/nominees"
              element={
                <Protected>
                  <NomineesHistoryPage />
                </Protected>
              }
            />
            <Route
              path="/soty"
              element={
                <Protected>
                  <SuccessOfTheYear />
                </Protected>
              }
            />
            <Route
              path="/faq"
              element={
                <Protected>
                  <Faq />
                </Protected>
              }
            />
          </Routes>
        </ThemeProvider>
      </SotmContextProvider>
    </Router>
  );
}

export default App;

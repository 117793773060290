import { VotesPerSessionIdDTO } from "../../models/types";
import { VotingSessionLabel } from "../../constants/VotingSession";
import { Stack, Tooltip, Typography } from "@mui/material";

export const NominationsHistoryVotesSection = ({ votes }: { votes: VotesPerSessionIdDTO[] }) => (
  <section className="votes-section">
    {votes.map((session, idx) => (
      <VotesPerSession key={`${session.sessionId}-${idx}`} votingSession={session} />
    ))}
  </section>
);

const VotesPerSession = ({ votingSession }: { votingSession: VotesPerSessionIdDTO }) => {
  return (
    <Tooltip placement={"bottom"} title={tooltipContent(votingSession)} leaveDelay={100}>
      <Typography className={`right-padding text-${votingSession.sessionId === "main" ? "muted" : "accent"}`}>
        <small data-testid={`vps-${votingSession.sessionId}`}>
          {VotingSessionLabel[votingSession.sessionId]}:&nbsp;{votingSession.votes.length}
        </small>
      </Typography>
    </Tooltip>
  );
};

const tooltipContent = (votingSession: VotesPerSessionIdDTO) => {
  return votingSession.votes === null || votingSession.votes.length === 0 ? (
    <small>No votes</small>
  ) : (
    <Stack>
      {votingSession.votes.map((voter) => (
        <small key={voter.userId}>{`${voter.firstName} ${voter.lastName}`}</small>
      ))}
    </Stack>
  );
};

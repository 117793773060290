import axios from "axios";
import {apiConfig, axiosConfigWithAuthorizationHeader} from "../common/api";
import {CompetitionWithNomineesDTO, CompetitionWithNomineesHistoryDTO, NominationDTO, Person} from "../models/types";

export const fetchNominationsHistoryByYear = async (accessToken: string, year: string) => {
  return axios
    .get<CompetitionWithNomineesHistoryDTO[]>(
      `${apiConfig.nominationsURL}/history/${year}`,
      axiosConfigWithAuthorizationHeader(accessToken),
    )
    .then((res) => res.data)
    .then((res) => {
      res.forEach((dto) => dto.nominees.sort((a, b) => b.votes.length - a.votes.length));
      return res;
    });
};

const prepareCreateOrEditNominationRequest = (nominee: Person, justification: string): NominationDTO => {
  return {
    nomineeUserId: nominee.userId,
    justification: justification,
  };
};

export const createNomination = async (accessToken: string, nominee: Person, justification: string) => {
  const requestBody = prepareCreateOrEditNominationRequest(nominee, justification);
  return axios.post(apiConfig.nominationsURL, requestBody, axiosConfigWithAuthorizationHeader(accessToken));
};

export const updateNomination = async (accessToken: string, nominee: Person, justification: string) => {
  const requestBody = prepareCreateOrEditNominationRequest(nominee, justification);
  return axios.put(apiConfig.nominationsURL, requestBody, axiosConfigWithAuthorizationHeader(accessToken));
};

export const deleteNomination = async (accessToken: string, nominee: Person, justification: string) => {
  return axios.delete(apiConfig.nominationsURL, {
    ...axiosConfigWithAuthorizationHeader(accessToken),
    params: {
      nominatedUserId: nominee.userId,
    },
  });
};

export const getCurrentCompetitions = async (accessToken: string): Promise<CompetitionWithNomineesDTO[]> => {
  return axios
    .get<CompetitionWithNomineesDTO[]>(apiConfig.nominationsURL, axiosConfigWithAuthorizationHeader(accessToken))
    .then((response) => {
      return response.data;
    });
};

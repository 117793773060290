import { Box, Divider, Typography } from "@mui/material";
import {CompetitionStatus, CompetitionWithNomineesDTO, NomineeDTO} from "../../models/types";
import { CompetitionId } from "../../common/competitionIdUtils";
import Nominee from "./Nominee";
import {memo, useContext} from "react";
import { theme } from "../../common/theme";
import {UserContext} from "../../state/userContext";

type CompetitionComponentProps = {
  competition: CompetitionWithNomineesDTO;
  isLastElement: boolean;
};

const Competition = ({ competition, isLastElement }: CompetitionComponentProps) => {
  const userContext = useContext(UserContext);

  function shouldBlur(nominee: NomineeDTO, status: CompetitionStatus) {
    return status == "NOMINATING" && nominee.nominations.filter((nomination) => nomination.fromPerson.userId === userContext.loggedUser?.userId).length === 0;
  }

  return (
    <>
      <Box className="competition">
        <SectionTitle {...competition} />
        {competition?.nominees.length ? (
          competition.nominees.map((nominee, idx, collection) => (
            <Nominee
              competitionId={competition.competitionId}
              competitionVotingSessionId={competition.lastVotingSessionId}
              competitionStatus={competition.status}
              nominee={nominee}
              key={nominee.nominatedPerson.userId}
              isLastElement={idx === collection.length - 1}
              blurred={shouldBlur(nominee, competition.status)}
            />
          ))
        ) : (
          <Box>No {typeOfCompetition(competition.status).toLowerCase()} for this month.</Box>
        )}
      </Box>
      {!isLastElement && (
        <Box className="divider">
          <Divider color={theme.custom.accentColor} className="mb-2x mt-2x divider" />
        </Box>
      )}
    </>
  );
};

export default Competition;

const SectionTitle = memo((competition: CompetitionWithNomineesDTO) => {
  return (
    <Typography className="section-heading" variant={"h4"}>
      {`${typeOfCompetition(competition.status)} for ${CompetitionId.fromIdString(
        competition.competitionId,
      ).monthName()}`}
    </Typography>
  );
});

const typeOfCompetition = (status: CompetitionStatus): string => {
  switch (status) {
    case "VOTING":
      return "Voting";
    case "PLAYOFF":
      return "Playoff";
    default:
      return "Nominations";
  }
};

import { memo } from "react";

type ProfilePictureProps = {
  photoUrl: string | undefined;
  type?: "light" | "dark";
  isStatic?: boolean;
  lazyLoading?: boolean;
};
const ProfilePicture = ({ photoUrl, type = "dark", isStatic = true, lazyLoading = true }: ProfilePictureProps) => (
  <img
    loading={lazyLoading ? "lazy" : "eager"}
    className={`profile-image avatar-shadow${isStatic ? " pos-static" : ""}`}
    data-testid="user-image"
    src={!photoUrl ? `no-image-${type}.jpeg` : photoUrl}
    alt={"profile-photo"}
    onError={(event) => (event.currentTarget.src = `no-image-${type}.jpeg`)}
  />
);
export default memo(ProfilePicture);

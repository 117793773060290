import axios from "axios";
import { apiConfig, axiosConfigWithAuthorizationHeader } from "../../common/api";

export const fetchAllFinishedCompetitionsIds = async (accessToken: string): Promise<string[]> => {
  const res = await axios.get<string[]>(
    apiConfig.competitionURL + "/finished",
    axiosConfigWithAuthorizationHeader(accessToken),
  );

  res.data.sort((a, b) => parseInt(b) - parseInt(a));

  return res.data;
};

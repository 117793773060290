import { Button } from "@mui/material";
import ProfilePicture from "../profilePicture/ProfilePicture";
import { CompetitionStatus, NomineeDTO } from "../../models/types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../state/userContext";
import VoteConfirmation from "./VoteConfirmation";
import NominatePersonDialog from "../nominatePersonDialog/NominatePersonDialog";

type NomineeLeftColumnProps = {
  competitionId: string;
  competitionVotingSessionId: string;
  competitionStatus: CompetitionStatus;
  nominee: NomineeDTO;
  blurred: boolean;
};

const NomineeLeftColumn = ({
  competitionId,
  competitionVotingSessionId,
  competitionStatus,
  nominee,
  blurred
}: NomineeLeftColumnProps) => {
  const userContext = useContext(UserContext);

  const [isJustificationDialogOpen, setIsJustificationDialogOpen] = useState(false);
  const [isVoteConfirmationDialogOpen, setIsVoteConfirmationDialogOpen] = useState(false);
  const [userHasAlreadyVotedInThisCompetition, setUserHasAlreadyVotedInThisCompetition] = useState(false);
  const [userHasAlreadyVotedInThisCompetitionForNominee, setUserHasAlreadyVotedInThisCompetitionForNominee] =
    useState(false);

  useEffect(() => {
    if (userContext.hasUserAlreadyVoted(competitionId, competitionVotingSessionId)) {
      setUserHasAlreadyVotedInThisCompetition(true);
    }

    if (
      userContext.hasUserAlreadyVotedForPerson(
        competitionId,
        competitionVotingSessionId,
        nominee.nominatedPerson.userId,
      )
    ) {
      setUserHasAlreadyVotedInThisCompetitionForNominee(true);
    }
  }, [userContext.loggedUser]);

  const hasUserAlreadyNominated = nominee.nominations.some(
    (nomination) => nomination.fromPerson.userId === userContext.loggedUser?.userId,
  );

  const canNominate = useMemo(() => {
    return competitionStatus === "NOMINATING" && !hasUserAlreadyNominated && !blurred;
  }, [competitionStatus, hasUserAlreadyNominated, blurred]);

  return (
    <div className="nominee-left-col">
      <ProfilePicture photoUrl={blurred ? './assets/pics/avatar_placeholder.jpg' : nominee.nominatedPerson?.photoUrl} />

      {canNominate && (
        <Button fullWidth variant="contained" onClick={() => setIsJustificationDialogOpen(true)}>
          Nominate
        </Button>
      )}
      {(competitionStatus === "VOTING" || competitionStatus === "PLAYOFF") && !userHasAlreadyVotedInThisCompetition && (
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setIsVoteConfirmationDialogOpen(true);
          }}
        >
          Vote
        </Button>
      )}
      {(competitionStatus === "VOTING" || competitionStatus === "PLAYOFF") &&
        userHasAlreadyVotedInThisCompetitionForNominee && (
          <Button
            fullWidth
            variant="contained"
            disabled={true}
            onClick={() => {
              setIsVoteConfirmationDialogOpen(true);
            }}
          >
            Voted
          </Button>
        )}

      <NominatePersonDialog
        open={isJustificationDialogOpen}
        nominee={nominee.nominatedPerson}
        onClose={() => {
          setIsJustificationDialogOpen(false);
        }}
      />

      <VoteConfirmation
        isOpen={isVoteConfirmationDialogOpen}
        competitionId={competitionId}
        nominee={nominee.nominatedPerson}
        onClose={() => {
          setIsVoteConfirmationDialogOpen(false);
        }}
      />
    </div>
  );
};

export default NomineeLeftColumn;

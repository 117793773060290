import ProfilePicture from "../profilePicture/ProfilePicture";
import { Typography } from "@mui/material";
import { theme } from "../../common/theme";

import { NomineeWithVotesDTO } from "../../models/types";
import { NominationsHistoryVotesSection } from "./NominationsHistoryVotesSection";
import { NominationsSection } from "./NominationsSection";

export const WinnerSection = ({ winner, month }: { winner: NomineeWithVotesDTO | undefined; month: string }) =>
  winner ? (
    <section data-testid={`winner-section-${month}`} className="nominee">
      <div className="nominee-left-col pos-static">
        <ProfilePicture photoUrl={winner.nominatedPerson?.photoUrl} />
      </div>
      <div className="nominee-right-col">
        <Typography color={theme.custom.accentColor}>Success of {month}</Typography>
        <Typography variant="h5" className="mb-0">
          {winner.nominatedPerson.firstName} {winner.nominatedPerson.lastName}
        </Typography>
        <NominationsHistoryVotesSection votes={winner.votes} />
        <NominationsSection nominations={winner.nominations} />
      </div>
    </section>
  ) : (
    <div data-testid={`placeholder-${month}`}>No winner in {month}</div>
  );

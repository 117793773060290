import React, { ReactElement, useContext, useEffect, useState } from "react";
import Page from "../../components/page/Page";
import { Paper } from "@mui/material";
import { YearlyWinner } from "../../models/types";
import "../../styles/pages/successOfTheYear.scss";
import { YearWinnerBox } from "../../components/successOfTheYear/YearWinnerBox";
import { UserContext } from "../../state/userContext";
import PageLoader from "../../components/fullPageLoader/PageLoader";
import { fetchAllYearlyWinners } from "../../services/successOfTheYear/successOfTheYearService";

const SuccessOfTheYear = (): ReactElement => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [yearlyWinners, setYearlyWinners] = useState<YearlyWinner[]>([]);

  useEffect(() => {
    fetchYearlyWinners()
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchYearlyWinners = async () => {
    const token = await userContext.getAuthToken();
    const years = await fetchAllYearlyWinners(token);
    setYearlyWinners(years);
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <Page>
      <Paper className="success-of-the-year">
        {yearlyWinners.map((winner, idx, collection) => (
          <YearWinnerBox key={winner.year} winner={winner} isLast={idx === collection.length - 1} />
        ))}
      </Paper>
    </Page>
  );
};

export default SuccessOfTheYear;

import {createTheme} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomTheme {
    custom: {
      accentColor?: string;
    };
  }

  interface Theme extends CustomTheme {
  }

  interface ThemeOptions extends CustomTheme {
  }
}

const accentColor = "#E30613";
const typographyHeaderStyle = {fontWeight: "bold"};
const baseSpacing = 15;
const boxShadow = '0px 5px 5px -4px rgb(0 0 0 / 20%)'
const baseTransition = 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

export const theme = createTheme({
  palette: {
    primary: {
      light: "#FFF",
      main: "#000",
      dark: "#000",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    background: {
      default: "#FFF",
      paper: "#FFF",
    },
  },
  typography: {
    fontFamily: "acumin-pro-wide",
    h1: typographyHeaderStyle,
    h2: typographyHeaderStyle,
    h3: typographyHeaderStyle,
    h4: typographyHeaderStyle,
    h5: typographyHeaderStyle,
    body1: {fontWeight: 200},
    body2: {fontWeight: 200},
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: baseSpacing,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          backgroundColor: "#000",
          margin: "20px",
          height: "95vh",
          width: "400px",
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          ":hover": {
            backgroundColor: accentColor,
            cursor: "pointer",
          },
          textTransform: "lowercase",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'svg': {
            color: 'white',
            transition: baseTransition,
            fontSize: '2rem'
          },
          '&:hover svg': {
            color: accentColor
          }
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          margin: 0,
          boxShadow,
          WebkitBoxShadow: boxShadow
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "744px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          ".MuiIconButton-root": {
            backgroundColor: "transparent",
            "&:hover": {
              color: accentColor,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: "0 24px 16px 24px",
        },
        root: {
          "button:hover": {
            backgroundColor: "white",
            color: accentColor,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: baseSpacing,
          marginBottom: baseSpacing,
          borderRadius: "0 !important",
          "&::before": {
            content: 'unset',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          display: "flex",
          gap: 24,
          ".profile-image": {
            verticalAlign: "middle",
            marginBottom: 23,
          },
          ".MuiFormControl-root": {
            width: "100%",

            ".MuiInputBase-root": {
              height: "100%",
              padding: 0,
            },

            ".MuiInputBase-input": {
              alignSelf: "baseline",
              padding: "10px 0 10px 15px",
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 2 * baseSpacing,
          marginBottom: 2 * baseSpacing,
        }
      }
    },
  },
  custom: {
    accentColor,
  },
});

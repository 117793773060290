import { CompetitionWithNomineesHistoryDTO, NomineeWithVotesDTO } from "../../models/types";
import ProfilePicture from "../profilePicture/ProfilePicture";
import { Divider, Typography } from "@mui/material";
import { theme } from "../../common/theme";
import MonthNames from "../../constants/MonthNames";
import { WinnerSection } from "./WinnerSection";
import { NominationsHistoryVotesSection } from "./NominationsHistoryVotesSection";
import { NominationsSection } from "./NominationsSection";
import { Fragment } from "react";

type HistoryCompetitionProps = {
  competition: CompetitionWithNomineesHistoryDTO;
  isLast: boolean;
};

export const HistoryCompetition = ({ competition, isLast }: HistoryCompetitionProps) => {
  const winner = competition.nominees.find((nominee) => nominee.nominatedPerson.userId === competition.winnerUserId);
  const nominees = competition.nominees.filter(
    (nominee) => nominee.nominatedPerson.userId !== competition.winnerUserId,
  );
  return (
    <>
      <Typography variant="h4" className="mb-2x month-name sticky-top">
        {MonthNames[new Date(competition.competitionId).getMonth()]}
      </Typography>
      <div className="row">
        <div className="competition col-half-responsive">
          <WinnerSection winner={winner} month={MonthNames[new Date(competition.competitionId).getMonth()]} />
        </div>
        <div className="competition col-half-responsive">
          <Nominees nominees={nominees} winnerUserId={winner?.nominatedPerson.userId} />
        </div>
      </div>
      {!isLast && <Divider color={theme.custom.accentColor} className="mb-2x mt-2x" />}
    </>
  );
};

const Nominees = ({ nominees, winnerUserId }: { nominees: NomineeWithVotesDTO[]; winnerUserId?: string }) => (
  <Fragment>
    {nominees.map((nominee, idx, collection) => {
      const isWinner = nominee.nominatedPerson.userId === winnerUserId;
      const showDivider = idx !== collection.length - 1;
      return (
        !isWinner && (
          <Fragment key={nominee.nominatedPerson.userId}>
            <div className="nominee">
              <div className="nominee-left-col pos-static">
                <ProfilePicture photoUrl={nominee.nominatedPerson?.photoUrl} />
              </div>
              <div className="nominee-right-col">
                <Typography color={theme.custom.accentColor}>Nominee</Typography>
                <Typography variant="h5" className="mb-0">
                  {nominee.nominatedPerson.firstName} {nominee.nominatedPerson.lastName}
                </Typography>
                <NominationsHistoryVotesSection votes={nominee.votes} />
                <NominationsSection nominations={nominee.nominations} />
              </div>
            </div>
            {showDivider && <hr />}
          </Fragment>
        )
      );
    })}
  </Fragment>
);

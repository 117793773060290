import { Box } from "@mui/material";
import CurrentCompetitions from "../../components/currentCompetitions/CurrentCompetitions";
import Page from "../../components/page/Page";
import EmployeeSearch from "../../components/employeeSearch/EmployeeSearch";
import EmployeesContextProvider, { EmployeesContext } from "../../state/employeesContext";
import CompetitionsContextProvider, { CompetitionsContext } from "../../state/competitionsContext";
import { useCallback, useContext } from "react";
import PageLoader from "../../components/fullPageLoader/PageLoader";

export const HomePage = () => {
  const { isLoaded: employeesLoaded } = useContext(EmployeesContext);
  const { isLoaded: competitionsLoaded } = useContext(CompetitionsContext);
  const isContentLoaded = employeesLoaded && competitionsLoaded;

  const PageContent = useCallback(
    () =>
      isContentLoaded ? (
        <Box className="home-page row">
          <EmployeeSearch />
          <CurrentCompetitions />
        </Box>
      ) : (
        <PageLoader />
      ),
    [isContentLoaded],
  );
  return (
    <Page>
      <PageContent />
    </Page>
  );
};

export default () => (
  <EmployeesContextProvider>
    <CompetitionsContextProvider>
      <HomePage />
    </CompetitionsContextProvider>
  </EmployeesContextProvider>
);

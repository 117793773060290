import { Dialog } from "@mui/material";
import { Person } from "../../../models/types";
import * as React from "react";
import { useContext } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { CompetitionsContext } from "../../../state/competitionsContext";

type DeleteNominationConfirmationProps = {
  open: boolean;
  nominee: Person;
  onClose: () => void;
};

const DeleteNominationConfirmation = ({ open, nominee, onClose }: DeleteNominationConfirmationProps) => {
  const competitionsContext = useContext(CompetitionsContext);

  const handleDeleteNomination = () => {
    competitionsContext.deleteNomination(nominee, "");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-nomination-confirmation-dialog"
      className="dialog-buttons"
    >
      <DialogTitle id="delete-nomination-confirmation-dialog-title">
        {`Do you want to remove your nomination to ${nominee.firstName} ${nominee.lastName}?`}
      </DialogTitle>
      <DialogActions className="actions">
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleDeleteNomination} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNominationConfirmation;

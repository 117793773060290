import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { useContext } from "react";
import { UserContext } from "../../state/userContext";
import "./LoginForm.styles.scss";

const LoginForm = () => {
  const userContext = useContext(UserContext);

  const handleSignIn = () => {
    userContext.login();
  };

  return (
    <div className="login-form-wrapper">
      <FormControl fullWidth className="login-form">
        <img src="./assets/pics/sotm_logo_black.svg" alt="img" />
        <Button variant="contained" aria-label="SignIn" onClick={handleSignIn}>
          Sign in using SSO
        </Button>
      </FormControl>
    </div>
  );
};

export default LoginForm;

import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Page from "../../components/page/Page";
import { theme } from "../../common/theme";

const Faq = () => {
  return (
    <Page>
      <h1>FAQ</h1>
      <p>"Success of the month" means the success of a person.</p>
      <p>
        Each of us understands the word "success" in a different way, starting from small, everyday behaviors, ending
        with spectacular achievements. 😊
      </p>
      <p>
        Examples of such behaviors can be: taking initiative, the ability to motivate other employees, willingness to
        help others - without wanting anything in return, an everyday positive attitude and a good mood, taking care of
        the environment, activities that drive the company to develop, and many more.
      </p>
      <Divider color={theme.custom.accentColor} className="mb-2x mt-2x divider" />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h5">How to nominate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              Nomination – explain why you want to appreciate a given person in the current month. It would be nice if
              you used our values!
            </p>
            <p>
              Votes for different nominations for one person will
              <b> ADD UP. The person with the largest number of votes wins.</b>
            </p>
            <p>
              You can write your nominations during the entire month using the SOTM application at&nbsp;
              <a href="https://sotm.grapeup.com">https://sotm.grapeup.com</a>.
            </p>
            <p>Each of us can submit any number of nominations for any person at the company.</p>
            <p>
              <b>ATTENTION!</b> The nomination does not mean voting for the Success of the Month!
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h5">How to vote?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Voting - voting for the person nominated for the previous month.</p>
            <p>
              During the first 7 days of each month&nbsp;
              <b>you can vote for one nominee. Remember that you can cast only one vote!</b>
            </p>
            <p>
              After the end of this period, the system will automatically select the winner who received&nbsp;
              <b>the most votes.</b> A draw between two or more candidates will start a second voting round.
            </p>
            <p>
              Information about the winner will be sent via email and on Slack. You can also check it anytime by logging
              in to the SOTM application.
            </p>
            <p>
              If, after voting, 2 or more nominees have the same number of votes, a play-off will start in the
              application. Then we have 3 days to vote again.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h5">Prize</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              The prize is a dinner funded by Grape Up in any restaurant with a budget of 250 PLN gross in Poland and
              150$ gross in the US (including a tip and a tax).
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h5">Settlement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Settlement is based on the invoice issued for Grape Up and delivered to the Administration team.</p>
            <p>
              After accepting the invoice, the gross amount from the invoice is transferred to the winner's account. The
              invoice amount cannot exceed PLN 250 gross or $150 gross (in the US).
            </p>
            <p>Billing:</p>
            <p>Grape Up Sp. z o.o.</p>
            <p>ul. Hetmana Stanisława Żółkiewskiego 17a </p>
            <p>31-539 Kraków</p>
            <p> NIP 945-217-93-09</p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h5">Success of the Year</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <Typography variant="h5">How to vote?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Each year there is a special survey sent by PepOps Team to vote for the Success of the Year. 😊</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <Typography variant="h5">Prize</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  The prize is a trip abroad to any place in the world funded by Grape Up worth PLN 25,000 (total labour
                  cost).
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <Typography variant="h5">Settlement</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>The settlement is individually agreed with the financial department.</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </Page>
  );
};
export default Faq;

import { useContext, useEffect, useState } from "react";
import { fetchAllFinishedCompetitionsIds } from "../../services/competition/competitionService";
import { UserContext } from "../../state/userContext";
import { NominationsHistoryAccordion } from "./NominationsHistoryAccordion";
import PageLoader from "../fullPageLoader/PageLoader";
import { Box } from "@mui/material";

export const NominationsHistory = () => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [competitionYears, setCompetitionYears] = useState<string[]>([]);

  useEffect(() => {
    fetchFinishedCompetitionsIds()
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchFinishedCompetitionsIds = async () => {
    const token = await userContext.getAuthToken();
    const years = await fetchAllFinishedCompetitionsIds(token);
    setCompetitionYears(years);
  };
  return isLoading ? (
    <PageLoader />
  ) : (
    <Box data-testid="nominationsHistory" className="nominations-history">
      {competitionYears.map((year) => (
        <NominationsHistoryAccordion year={year} key={year} />
      ))}
    </Box>
  );
};

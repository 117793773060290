import { AxiosRequestConfig } from "axios";

export const apiConfig = {
  votingURL: process.env.REACT_APP_API_URL + "/vote",
  nominationsURL: process.env.REACT_APP_API_URL + "/nomination",
  competitionURL: process.env.REACT_APP_API_URL + "/competition",
  winnersURL: process.env.REACT_APP_API_URL + "/winner",
  yearlyWinnersURL: process.env.REACT_APP_API_URL + "/yearly-winner",
  usersURL: process.env.REACT_APP_API_URL + "/users",
};

export const axiosConfigWithAuthorizationHeader = (token: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const isResponseSuccess = (responseCode: number): boolean => {
  return responseCode >= 200 && responseCode < 300;
};

import ProfilePicture from "../profilePicture/ProfilePicture";
import { theme } from "../../common/theme";
import React from "react";
import { YearlyWinner } from "../../models/types";
import { Divider, Typography } from "@mui/material";

type YearWinnerBoxProps = {
  winner: YearlyWinner;
  isLast: boolean;
};

export const YearWinnerBox = ({ winner, isLast }: YearWinnerBoxProps) => {
  return (
    <div className="mb-2x mt-2x">
      <div className="row">
        <div className="col-half-responsive">
          <img src="./assets/pics/SOTY-logo-cropped.svg" alt="img" className="logo" />
          <Typography className="year-text">{winner.year}</Typography>
        </div>

        <div className="competition col-half-responsive">
          <div className="nominee">
            <div className="nominee-left-col pos-static">
              <ProfilePicture photoUrl={winner.person?.photoUrl} />
            </div>
            <div className="nominee-right-col">
              <Typography variant="h5" className="mb-0x">
                {winner.person.firstName} {winner.person.lastName}
              </Typography>
              {winner.nominations?.map((nomination, idx, collection) => (
                <Typography variant="body1" className={idx === collection.length - 1 ? "" : "mb-1x"}>
                  {nomination}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!isLast && <Divider color={theme.custom.accentColor} className="mb-2x mt-2x" />}
    </div>
  );
};

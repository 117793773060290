import * as React from "react";
import { useState } from "react";
import { Person } from "../../../models/types";
import DeleteNominationConfirmation from "./DeleteNominationConfirmation";
import NominatePersonDialog from "../../nominatePersonDialog/NominatePersonDialog";

type NominationEditMenuProps = {
  nomineePerson: Person;
  justification: string;
};

const NominationManageActions = ({ nomineePerson, justification }: NominationEditMenuProps) => {
  const [isJustificationDialogOpen, setIsJustificationDialogOpen] = useState(false);
  const [isDeleteNominationConfirmationDialogOpen, setIsDeleteNominationConfirmationDialogOpen] = useState(false);

  return (
    <>
      <small className="action-buttons">
        <span onClick={() => setIsDeleteNominationConfirmationDialogOpen(true)}>delete</span>
        <span>&nbsp;|&nbsp;</span>
        <span onClick={() => setIsJustificationDialogOpen(true)}>edit</span>
      </small>

      <NominatePersonDialog
        open={isJustificationDialogOpen}
        nominee={nomineePerson}
        justificationParam={justification}
        onClose={() => {
          setIsJustificationDialogOpen(false);
        }}
      />

      <DeleteNominationConfirmation
        open={isDeleteNominationConfirmationDialogOpen}
        nominee={nomineePerson}
        onClose={() => {
          setIsDeleteNominationConfirmationDialogOpen(false);
        }}
      />
    </>
  );
};

export default NominationManageActions;

import SotmAppBar from "../appBar/SotmAppBar";

type PageProps = {
  children: React.ReactNode;
};

const Page = (props: PageProps) => (
  <>
    <SotmAppBar />
    <main>
      <div className="container">{props.children}</div>
    </main>
  </>
);

export default Page;

import ProfilePicture from "../profilePicture/ProfilePicture";
import { Box, Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { Person } from "../../models/types";
import { useContext, useEffect, useState } from "react";
import { CompetitionsContext } from "../../state/competitionsContext";
import "./NominationJustification.style.scss";

type NominationJustificationProps = {
  nominee: Person | null;
  justificationParam?: string;
  onClose: () => void;
};
export const NominationJustification = ({ nominee, justificationParam, onClose }: NominationJustificationProps) => {
  const MIN_JUSTIFICATION_LENGTH = 100;
  const MAX_JUSTIFICATION_LENGTH = 800;

  const INITIAL_VALUES = {
    justification: justificationParam || "",
    validationError: null as string | null,
    confirmDisabled: true,
  };

  const competitionsContext = useContext(CompetitionsContext);

  const [justification, setJustification] = useState<string>(INITIAL_VALUES.justification);
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(INITIAL_VALUES.confirmDisabled);

  useEffect(() => {
    setJustification(justificationParam || "");
  }, [justificationParam]);

  const tooFewCharacters = (text: string): boolean => {
    return text.length < MIN_JUSTIFICATION_LENGTH;
  };
  const tooManyCharacters = (text: string): boolean => {
    return text.length > MAX_JUSTIFICATION_LENGTH;
  };

  const isJustificationTextValid = (text: string): boolean => {
    return !tooFewCharacters(text) && !tooManyCharacters(text);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (nominee === null) {
      throw Error("Nominee can't be null! Something went wrong");
    }

    if (justificationParam) {
      competitionsContext.updateNomination(nominee, justification).catch((error) => console.error(error));
    } else {
      competitionsContext.nominate(nominee, justification).catch((error) => console.error(error));
    }
    clearStateAndClose();
  };

  const handleChange = (event: any) => {
    const text = event.target.value;
    setJustification(text);
    setConfirmDisabled(!isJustificationTextValid(text));
  };

  const clearStateAndClose = () => {
    setJustification(INITIAL_VALUES.justification);
    setConfirmDisabled(INITIAL_VALUES.confirmDisabled);
    onClose();
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      data-testid="nomination-justification"
      className="nomination-justification"
    >
      <DialogContent>
        <Box>
          <div data-testid="nominee-initials" className="initials">
            {nominee?.firstName}
            <br />
            {nominee?.lastName}
          </div>
          <ProfilePicture photoUrl={nominee?.photoUrl} />
        </Box>
        <Box className="justification-box">
          <TextField
            id="justification"
            data-testid="justification"
            name="justification"
            value={justification}
            multiline
            autoFocus
            maxRows={7}
            onChange={handleChange}
            onAbort={clearStateAndClose}
            placeholder={`Tell everybody why you have nominated ${nominee?.firstName} and how their efforts support the company's vision and values.`}
            className="justification-text"
          />
          <div className="justification-text-helpers text-muted">
            {tooFewCharacters(justification) && (
              <Typography fontSize="small" data-testid="to-few-prompt" className="to-few-prompt">
                {`${MIN_JUSTIFICATION_LENGTH - justification.length} more to go`}
              </Typography>
            )}
            <Typography fontSize="small" data-testid="current-length-prompt" className="current-length-prompt">
              {`${justification.length}/${MAX_JUSTIFICATION_LENGTH}`}
            </Typography>
          </div>
        </Box>
      </DialogContent>
      <JustificationActions onClose={clearStateAndClose} confirmDisabled={confirmDisabled} />
    </Box>
  );
};

type JustificationActionsProps = {
  confirmDisabled: boolean;
  onClose: () => void;
};
const JustificationActions = ({ onClose, confirmDisabled }: JustificationActionsProps) => (
  <DialogActions className="actions">
    <Button variant="contained" onClick={onClose}>
      Close
    </Button>
    <Button variant="contained" data-testid="confirm-button" type="submit" disabled={confirmDisabled}>
      Confirm
    </Button>
  </DialogActions>
);

import React, { useContext, useEffect, useState } from "react";
import { Person } from "../models/types";
import { getAllUsers } from "../services/userService";
import { UserContext } from "./userContext";

type EmployeesContextType = {
  employees: Person[];
  isLoaded: boolean;
};
const initialState: EmployeesContextType = {
  employees: [],
  isLoaded: false,
};
export const EmployeesContext = React.createContext<EmployeesContextType>(initialState);

const EmployeesContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [employees, setEmployees] = useState<Person[]>(initialState.employees);
  const [isLoaded, setIsLoaded] = useState(initialState.isLoaded);
  const userContext = useContext(UserContext);

  const fetchEmployees = async () => {
    try {
      const token = await userContext.getAuthToken();
      const res = await getAllUsers(token);
      setEmployees(res);
    } catch (err) {
      console.error("Axios err: ", err);
    }
  };

  useEffect(() => {
    fetchEmployees().finally(() => setIsLoaded(true));
  }, []);

  return (
    <EmployeesContext.Provider
      value={{
        employees,
        isLoaded,
      }}
    >
      {props.children}
    </EmployeesContext.Provider>
  );
};

export default EmployeesContextProvider;

export class CompetitionId {
  year: number;
  month: number;
  id: string;

  constructor(year: number, month: number, id: string) {
    this.year = year;
    this.month = month;
    this.id = id;
  }

  static fromIdString(competitionId: string): CompetitionId {
    const year = +competitionId.split(/\s*-\s*/)[0];
    const month = +competitionId.split(/\s*-\s*/)[1] - 1;
    return new CompetitionId(year, month, competitionId);
  }

  monthNameAndYear(): string {
    return `${this.monthName()} ${this.year}`;
  }

  asDate(): Date {
    const date = new Date();
    date.setMonth(this.month);
    date.setFullYear(this.year);
    return date;
  }

  monthName() {
    const date = new Date();
    date.setMonth(this.month);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }
}

import { Dialog, DialogTitle } from "@mui/material";
import { memo, useContext } from "react";
import { Person } from "../../models/types";
import { UserContext } from "../../state/userContext";
import { SelfNomination } from "./SelfNomination";
import { NominationJustification } from "./NominationJustification";
import MonthNames from "../../constants/MonthNames";

type NominationProps = {
  open: boolean;
  nominee: Person | null;
  justificationParam?: string;
  onClose: () => void;
};

const NominatePersonDialog = ({ open, nominee, justificationParam, onClose }: NominationProps) => {
  const userContext = useContext(UserContext);

  return (
    <Dialog
      data-testid="nominate-person-dialog"
      className="justification-dialog dialog-buttons"
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="dialog-title" data-testid="nominate-person-dialog-title">
        <h4>{`Justification of nomination for ${MonthNames[new Date().getMonth()]}`}</h4>
      </DialogTitle>
      {userContext.loggedUser?.userId === nominee?.userId ? (
        <SelfNomination onClose={onClose} nominee={nominee} />
      ) : (
        <NominationJustification nominee={nominee} justificationParam={justificationParam} onClose={onClose} />
      )}
    </Dialog>
  );
};

export default memo(NominatePersonDialog);

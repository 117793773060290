import { memo } from "react";
import { CircularProgress } from "@mui/material";
import "./PageLoader.styles.scss";

const PageLoader = memo(() => (
  <div data-testid="pageLoader" className="page-loader">
    <CircularProgress />
  </div>
));

PageLoader.displayName = "PageLoader";
export default PageLoader;

import { memo } from "react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";

type BoxWithLoaderProps = {
  loadingText?: string;
  background?: boolean;
};
const BoxWithLoader = ({ loadingText, background = true }: BoxWithLoaderProps) => {
  const renderSpinnerWithText = () => (
    <Box data-testid='boxWithLoader' className="box-with-loader">
      {loadingText && <Typography variant={"subtitle1"}>{loadingText}&nbsp;</Typography>}
      <CircularProgress size="1.25rem" />
    </Box>
  );
  return background ? <Paper className="box-with-loader">{renderSpinnerWithText()}</Paper> : renderSpinnerWithText();
};

export default memo(BoxWithLoader);

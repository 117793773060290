import React from "react";
import { SnackbarContextProvider } from "./snackbarContext";
import UserContextProvider from "./userContext";

const SotmContext = React.createContext({});

export const SotmContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const contextValue = {};

  return (
    <SotmContext.Provider value={contextValue}>
      <SnackbarContextProvider>
        <UserContextProvider>{props.children}</UserContextProvider>
      </SnackbarContextProvider>
    </SotmContext.Provider>
  );
};

import { Divider, Drawer, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { UserContext } from "../../state/userContext";
import { theme } from "../../common/theme";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "../profilePicture/ProfilePicture";

type DrawerProps = {
  state: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const DrawerHeader = () => {
  const userContext = useContext(UserContext);
  return (
    <>
      <Stack direction="row" spacing={2}>
        <ProfilePicture photoUrl={userContext?.loggedUser?.photoUrl} type="light" lazyLoading={false}></ProfilePicture>
        <Stack direction="column" justifyContent="space-between">
          <Typography variant="subtitle1" fontWeight="bold">
            {userContext.loggedUser?.firstName} {userContext.loggedUser?.lastName}
          </Typography>
          <Button onClick={userContext.logout} size="small" fullWidth={false}>
            Logout
          </Button>
        </Stack>
      </Stack>
      <Divider color={theme.custom.accentColor} />
    </>
  );
};

const DrawerItem = (props: { path: string; text: string }) => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(props.path)}>
      <h1>{props.text}</h1>
    </Button>
  );
};

const SotmDrawer = ({ state, toggleDrawer }: DrawerProps) => {
  return (
    <Drawer anchor={"right"} className="drawer" open={state} onClose={toggleDrawer(false)} data-testid="drawer">
      <Stack style={{ margin: "30px" }}>
        <DrawerHeader />
        <DrawerItem path="/" text="Home" />
        <DrawerItem path="/nominees" text="Nominees" />
        <DrawerItem path="/soty" text="Success Of The Year" />
        <DrawerItem path="/faq" text="FAQ" />
      </Stack>
    </Drawer>
  );
};

export default SotmDrawer;

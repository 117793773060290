import { Box, Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { Person } from "../../models/types";
import ProfilePicture from "../profilePicture/ProfilePicture";

type SelfNominationProps = {
  nominee: Person | null;
  onClose: () => void;
};
export const SelfNomination = ({ onClose, nominee }: SelfNominationProps) => {
  return (
    <Box data-testid="self-nomination">
      <DialogContent>
        <Box>
          <div data-testid="nominee-initials" className="initials">
            {nominee?.firstName}
            <br />
            {nominee?.lastName}
          </div>
          <ProfilePicture photoUrl={nominee?.photoUrl} />
        </Box>
        <Stack direction="column" spacing={2}>
          <Typography data-testid="self-nomination-text" variant="h5">
            Hola, wait a minute, it's you!
          </Typography>
          <img data-testid="self-nomination-gif" src="./assets/self-five.gif" alt="img" />
        </Stack>
      </DialogContent>
      <DialogActions className="actions">
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Box>
  );
};

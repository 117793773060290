import { CompetitionStatus, NomineeDTO } from "../../models/types";
import { Typography } from "@mui/material";
import Nomination from "./NominationTile";
import NomineeLeftColumn from "./NomineeLeftColumn";
import { useMemo } from "react";

type Props = {
  nominee: NomineeDTO;
  competitionId: string;
  competitionVotingSessionId: string;
  competitionStatus: CompetitionStatus;
  isLastElement: boolean;
  blurred: boolean;
};

const placeholderNominee = [
    "Grzegorz Brzęczyszczykiewicz",
    "Jan Kowalski",
    "Janusz Tracz",
    "Zbigniew Niedźwiedź",
    "Aleksandra Nowak",
    "Małgorzata Kowalska"
]

const Nominee = ({ nominee, competitionId, competitionVotingSessionId, competitionStatus, isLastElement, blurred }: Props) => {
  const renderNominations = useMemo(
    () =>
      nominee.nominations.map((nomination, idx, collection) => (
        <Nomination
          key={nomination.fromPerson.userId}
          nominee={nominee.nominatedPerson}
          nomination={nomination}
          competitionStatus={competitionStatus}
          blurred={blurred}
        />
      )),
    [nominee, competitionStatus, blurred],
  );
  return (
    <>
      <div className="nominee">
        <NomineeLeftColumn
          competitionId={competitionId}
          competitionVotingSessionId={competitionVotingSessionId}
          competitionStatus={competitionStatus}
          nominee={nominee}
          blurred={blurred}
        />
        <div className="nominee-right-col">
          <Typography variant="h5" className={blurred ? 'blurred' : ''}>
            {blurred ? placeholderNominee[Math.floor(Math.random() * placeholderNominee.length)] : nominee.nominatedPerson.firstName} {blurred ? '' : nominee.nominatedPerson.lastName}
          </Typography>
          {renderNominations}
        </div>
      </div>
      {!isLastElement && <hr />}
    </>
  );
};

export default Nominee;

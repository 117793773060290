import React, { useContext, useEffect, useState } from "react";
import { CompetitionWithNomineesDTO, NominationActionType, Person } from "../models/types";
import { UserContext } from "./userContext";
import {
  createNomination,
  deleteNomination,
  getCurrentCompetitions,
  updateNomination,
} from "../services/nominationService";
import { SnackbarContext } from "./snackbarContext";
import { isResponseSuccess } from "../common/api";

type CompetitionsContextType = {
  competitions: CompetitionWithNomineesDTO[];
  nominate: (nominee: Person, justification: string) => Promise<void>;
  updateNomination: (nominee: Person, justification: string) => Promise<void>;
  deleteNomination: (nominee: Person, justification: string) => Promise<void>;
  isLoading: boolean;
  isLoaded: boolean;
};

export const CompetitionsContext = React.createContext<CompetitionsContextType>({
  competitions: [],
  nominate() {
    return Promise.resolve();
  },
  updateNomination() {
    return Promise.resolve();
  },
  deleteNomination() {
    return Promise.resolve();
  },
  isLoading: true,
  isLoaded: false,
});

const CompetitionsContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [competitions, setCompetitions] = useState<CompetitionWithNomineesDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  useEffect(() => {
    fetchCompetitions()
      .catch((err) => console.error(err))
      .finally(() => setIsLoaded(true));
  }, []);

  const getNominationAction = (type: NominationActionType) => {
    switch (type) {
      case "delete":
        return deleteNomination;
      case "update":
        return updateNomination;
      default:
        return createNomination;
    }
  };

  const nominationAction = (type: NominationActionType) => async (nominee: Person, justification: string) => {
    const snackActionPrefix = `Nomination ${type === "create" ? "" : type}`;
    await snackbarContext.openInfoSnack(`${snackActionPrefix} sent`);
    const token = await userContext.getAuthToken();

    try {
      const res = await getNominationAction(type)(token, nominee, justification);
      if (isResponseSuccess(res.status)) {
        await snackbarContext.openSuccessSnack(`${snackActionPrefix} success`);
      }
    } catch (err) {
      console.error(err);
      await snackbarContext.openErrorSnack(`${snackActionPrefix} failed`);
    }

    await fetchCompetitions();
  };

  const fetchCompetitions = async () => {
    setIsLoading(true);
    try {
      const token = await userContext.getAuthToken();
      const competitions = await getCurrentCompetitions(token);
      setCompetitions(competitions);
    } catch (err) {
      console.log("Axios err: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  const contextValue: CompetitionsContextType = {
    competitions,
    nominate: nominationAction("create"),
    isLoading,
    isLoaded,
    updateNomination: nominationAction("update"),
    deleteNomination: nominationAction("delete"),
  };

  return <CompetitionsContext.Provider value={contextValue}>{props.children}</CompetitionsContext.Provider>;
};

export default CompetitionsContextProvider;

import { Box, Stack, Typography } from "@mui/material";
import { CompetitionStatus, Nomination as NominationType, Person } from "../../models/types";
import NominationManageActions from "./nominationManage/NominationManageActions";
import { useContext } from "react";
import { UserContext } from "../../state/userContext";
import { theme } from "../../common/theme";

type NominationProps = {
  nominee: Person;
  nomination: NominationType;
  competitionStatus: CompetitionStatus;
  blurred: boolean;
};

const placeholderJustification = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse lobortis aliquam sem, eget molestie velit pulvinar eu. Cras vel molestie lorem, vel venenatis nibh. Integer ac nulla tincidunt, cursus diam vel, accumsan quam. Duis finibus ante nisl, ut porta libero tristique laoreet. Suspendisse scelerisque metus quis neque vehicula interdum. Quisque pellentesque vulputate ipsum ut scelerisque. Morbi nec urna nec leo varius aliquet venenatis ac leo. Fusce posuere blandit sapien, vitae pretium mi porttitor sed. Morbi vestibulum cursus tellus, tincidunt fringilla diam eleifend sed. Sed ut velit viverra, ultrices massa eget, consectetur diam. Vestibulum egestas malesuada sapien eu elementum. Mauris mattis dui in consequat posuere.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur tempor lectus. Cras eget mauris velit. Phasellus malesuada ac sem sed egestas. Curabitur eu ullamcorper ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis vitae augue vel nisi volutpat pretium id quis velit. Mauris a volutpat ipsum. Donec ac condimentum eros. Donec malesuada euismod ipsum egestas euismod.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum sollicitudin suscipit. Phasellus lorem libero, laoreet vel metus vitae, aliquam pellentesque eros. Donec in urna nec tortor congue ornare. Pellentesque et sagittis lectus. Vestibulum est justo, dignissim dictum elementum at, varius et eros. Praesent at elit quis mauris molestie ultrices. Fusce aliquet ante ut urna elementum feugiat. Praesent efficitur non lectus in mattis. Aenean suscipit augue ut dictum faucibus. In turpis enim, accumsan non porttitor ac, imperdiet eget ipsum. Curabitur facilisis arcu eu risus rhoncus vestibulum tristique sit amet tellus. Integer iaculis quam non eros molestie, sed lacinia nibh sagittis. In hac habitasse platea dictumst. Aenean vehicula urna quis diam congue, sed scelerisque dolor posuere. Proin tortor purus, sagittis at lobortis in, accumsan et mauris. Nullam mattis, nunc eget tincidunt feugiat, elit massa ornare massa, vel pulvinar risus neque id lacus."
];
const placeholderNominator = [
    "John Doe",
    "Jane Doe",
    "Alice Smith",
    "Bob Johnson",
    "Charlie Brown",
];

const Nomination = ({ nominee, nomination, competitionStatus, blurred }: NominationProps) => {
  const userContext = useContext(UserContext);

  const canUserEditNomination = nomination.fromPerson.userId === userContext.loggedUser?.userId;
  const isNominationEditable = competitionStatus === "NOMINATING" && canUserEditNomination;
  const placeholderIndex = Math.floor(Math.random() * placeholderJustification.length);

  return (
    <Box className="nomination-tile">
      <Typography variant="body1" className={blurred ? 'blurred' : ''} gutterBottom>
        {blurred ? placeholderJustification[placeholderIndex] : nomination.justification}
      </Typography>
      <Stack direction="row" flexWrap={"nowrap"} justifyContent="space-between">
        <Typography sx={{ flexShrink: 1, alignItems: "center" }}>
          <small style={{ color: theme.custom.accentColor }}>nominated by&nbsp;</small>
          <small>
            <strong className={blurred ? 'blurred' : ''} >
              {blurred ? placeholderNominator[placeholderIndex] : nomination.fromPerson.firstName}&nbsp;{blurred ? '' : nomination.fromPerson.lastName}
            </strong>
          </small>
        </Typography>
        {isNominationEditable && (
          <NominationManageActions nomineePerson={nominee} justification={nomination.justification} />
        )}
      </Stack>
    </Box>
  );
};
export default Nomination;
